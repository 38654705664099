import {
    getSimpleProducts,
    ProductInput,
    createGetFeatureStateInput,
    getCustomer,
    GetCustomerInput,
    getFeatureState,
    ArrayExtensions
} from '@msdyn365-commerce-modules/retail-actions';
import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICreateActionContext
} from '@msdyn365-commerce/core';
import { getCartState } from '@msdyn365-commerce/global-state';
import { CommerceProperty, CommercePropertyValue, ProductSearchCriteria, FeatureState } from '@msdyn365-commerce/retail-proxy';
import { searchByCriteriaAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { Cart, SimpleProduct } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { getRestrictedProducts } from '../utilities/post-code-restriction/get-restricted-products';
/**
 * Input class for activeCartWithProducts data action
 */
export class ActiveCartProductsInput implements IActionInput {
    public getCacheKey = () => `ActiveCartProducts`;
    public getCacheObjectType = () => 'ActiveCartProducts';
    public dataCacheType = (): CacheType => 'none';
}

const createInput = (inputData: ICreateActionContext) => {
    return new ActiveCartProductsInput();
};

/**
 * Calls the Retail API and returns a cart object based on the passed GetCartInput
 * @param input
 * @param ctx
 */
export async function getActiveCartProductsAction(input: ActiveCartProductsInput, ctx: IActionContext): Promise<SimpleProduct[]> {
    // If no cart ID is provided in input, we need to create a cart object
    if (!input) {
        throw new Error('[getActiveCartWithProducts]No valid Input was provided, failing');
    }

    const cartState = await getCartState(ctx);
    const cart = cartState.cart;

    const isQuantityLimitsFeatureIsOn: boolean = await isOrderQuantityLimitsFeatureEnabled(ctx);

    if (isQuantityLimitsFeatureIsOn) {
        return getActiveCartProductsActionWhenQuantityLimitsFeatureIsOn(cart, ctx);
    }

    // If there are cart lines, make call to get products
    if (!cartState.hasInvoiceLine && cart && cart.CartLines && cart.CartLines.length > 0) {
        ctx.trace('Getting cart product information...');
        return getSimpleProducts(
            <ProductInput[]>cart.CartLines.map(cartLine => {
                if (cartLine.ProductId) {
                    return new ProductInput(cartLine.ProductId, ctx.requestContext.apiSettings);
                }
                return undefined;
            }).filter(Boolean),
            ctx
        )
            .then((products: SimpleProduct[]) => {
                if (products) {
                    /* VSI Customization - START - 15/12/20 */
                    const productWithVendorInfo = _getProductsVendorInfo(ctx, products);
                    if (productWithVendorInfo) {
                        return productWithVendorInfo;
                    }
                    return products;
                    /* VSI Customization - END */
                } else {
                    return [];
                }
            })
            .catch((error: Error) => {
                ctx.trace(error.toString());
                ctx.telemetry.error(error.message);
                ctx.telemetry.debug(`[getActiveCartWithProducts]Unable to hydrate cart with product information`);
                throw new Error('[getActiveCartWithProducts]Unable to hydrate cart with product information');
            });
    }

    ctx.trace('[getActiveCartWithProducts]No Products Found in cart');
    return <SimpleProduct[]>[];
}
/* VSI Customization - START - 15/12/20 */
const _getProductsVendorInfo = async (actionContext: IActionContext, products: SimpleProduct[]) => {
    const callerContext = actionContext;
    const {
        requestContext: {
            apiSettings: { channelId, catalogId }
        }
    } = actionContext;
    if (products) {
        const searchCriteriaInput: ProductSearchCriteria = {};
        searchCriteriaInput.Context = { ChannelId: channelId, CatalogId: catalogId };
        const productIds: number[] = [];
        const masterProductIds: number[][] = [];
        products.map(product => {
            if (product.MasterProductId && product.RecordId !== product.MasterProductId) {
                masterProductIds.push([product.MasterProductId, product.RecordId]);
                productIds.push(product.MasterProductId);
            } else {
                productIds.push(product.RecordId);
            }
        });
        searchCriteriaInput.Ids = productIds;
        searchCriteriaInput.IncludeAttributes = true;
        const productsData = await searchByCriteriaAsync({ callerContext, queryResultSettings: {} }, searchCriteriaInput);
        
        
        // Get restricted products list
        const restrictedProducts = await getRestrictedProducts(productsData, actionContext.requestContext.apiSettings.baseUrl);
        productsData.map(product => {
            for (const mp of masterProductIds) {
                if (mp[0] === product.RecordId) {
                    product.RecordId = mp[1];
                }
            }
            // Check if it is restricted product, add it in its ExtensionProperties
            const isRestrictedProduct = restrictedProducts.find(productId => productId === product.RecordId);
            const restrictedInfo: CommerceProperty = { Key: 'isRestrictedProduct', Value: { BooleanValue: !!isRestrictedProduct } };

            const attributes = product.AttributeValues;
            const isvendorShippedAttribute =
                attributes &&
                attributes.find(attribute => {
                    const attributeName = attribute.Name && attribute.Name.trim().toLowerCase();
                    return attributeName === 'isvendershipproduct'; // "IsVendershipProduct"
                });
            const isVendorShiped =
                isvendorShippedAttribute &&
                isvendorShippedAttribute.TextValue &&
                isvendorShippedAttribute.TextValue.trim().toLowerCase() === 'yes';
            const extensionProperties: CommerceProperty[] = [];
            if (isVendorShiped) {
                const selectedAttributes =
                    attributes &&
                    attributes.filter(attribute => {
                        const attributeName = attribute.Name && attribute.Name.trim().toLowerCase();
                        const isVendorNameAvailable = attribute.TextValue; // if vendor name is not undefined
                        return (
                            attributeName &&
                            isVendorNameAvailable &&
                            (attributeName === 'vendorname' || attributeName === 'estimatedshippingtime')
                        );
                    });
                if (selectedAttributes && selectedAttributes.length > 0) {
                    selectedAttributes &&
                        selectedAttributes.map(selectedAttribute => {
                            const commercePropertyValue: CommercePropertyValue = { StringValue: selectedAttribute.TextValue };
                            const commerceProperty: CommerceProperty = { Key: selectedAttribute.Name, Value: commercePropertyValue };
                            extensionProperties.push(commerceProperty);
                        });
                } else {
                    const vendorname: CommerceProperty = { Key: 'vendorname', Value: { StringValue: 'Not Available' } };
                    const estimatedShippingTime: CommerceProperty = {
                        Key: 'estimatedshippingtime',
                        Value: { StringValue: 'Not Available' }
                    };
                    extensionProperties.push(vendorname);
                    extensionProperties.push(estimatedShippingTime);
                }
            } else {
                const vendorname: CommerceProperty = { Key: 'vendorname', Value: { StringValue: undefined } };
                const estimatedShippingTime: CommerceProperty = { Key: 'estimatedshippingtime', Value: { StringValue: undefined } };
                extensionProperties.push(vendorname);
                extensionProperties.push(estimatedShippingTime);
            }
            // Add restricted info against each cartLine
            extensionProperties.push(restrictedInfo);
            product.ExtensionProperties = extensionProperties;
        });
        return <SimpleProduct[]>productsData;
    }
    return [];
};
/* VSI Customizatoin - END */
export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-products-in-active-cart',
    action: <IAction<SimpleProduct[]>>getActiveCartProductsAction,
    input: createInput
});

async function getActiveCartProductsActionWhenQuantityLimitsFeatureIsOn(cart: Cart, ctx: IActionContext): Promise<SimpleProduct[]> {
    const productIdsByWarehouseId: Map<string, number[]> = new Map();
    let resultProducts: SimpleProduct[] = [];
    cart.CartLines?.forEach(cartLine =>
        productIdsByWarehouseId.has(cartLine.WarehouseId!)
            ? productIdsByWarehouseId.get(cartLine.WarehouseId!)?.push(cartLine.ProductId!)
            : productIdsByWarehouseId.set(cartLine.WarehouseId!, [cartLine.ProductId!])
    );
    return Promise.all(
        [...productIdsByWarehouseId].map(([entryWarehouseId, entryProductIds]) => {
            return getSimpleProducts(
                <ProductInput[]>entryProductIds
                    .map(productId => {
                        if (productId) {
                            return new ProductInput(productId, ctx.requestContext.apiSettings, undefined, entryWarehouseId);
                        }
                        return undefined;
                    })
                    .filter(Boolean),
                ctx
            ).then((products: SimpleProduct[]) => {
                if (products) {
                    resultProducts = products.reduce((accum, product) => {
                        if (product) {
                            accum.push(product);
                        }
                        return accum;
                    }, resultProducts);
                }
            });
        })
    ).then(() => resultProducts);
}

async function isOrderQuantityLimitsFeatureEnabled(ctx: IActionContext): Promise<boolean> {
    const defaultOrderQuantityLimitsFeatureConfig = ctx.requestContext.app?.platform?.enableDefaultOrderQuantityLimits;
    if (defaultOrderQuantityLimitsFeatureConfig === 'none') {
        return Promise.resolve(false);
    }

    const featureStates = await getFeatureState(createGetFeatureStateInput(ctx), ctx);
    let isQuantityLimitsFeatureEnabledInHq = false;
    if (ArrayExtensions.hasElements(featureStates)) {
        isQuantityLimitsFeatureEnabledInHq =
            featureStates.find(
                (featureState: FeatureState) => featureState.Name === 'Dynamics.AX.Application.RetailDefaultOrderQuantityLimitsFeature'
            )?.IsEnabled || false;
    }

    if (!isQuantityLimitsFeatureEnabledInHq) {
        return false;
    }

    if (defaultOrderQuantityLimitsFeatureConfig === 'all') {
        return Promise.resolve(true);
    }

    return getCustomer(new GetCustomerInput(ctx.requestContext.apiSettings), ctx)
        .then(customerInfo => {
            return (
                !!customerInfo &&
                ((defaultOrderQuantityLimitsFeatureConfig === 'b2b' && customerInfo.IsB2b) ||
                    (defaultOrderQuantityLimitsFeatureConfig === 'b2c' && !customerInfo.IsB2b))
            );
        })
        .catch((error: Error) => {
            ctx.telemetry.warning(error.message);
            ctx.telemetry.debug('Unable to get customer info');
            return false;
        });
}
