import {
    IAny,
    ICreateActionContext,
    IGeneric,
} from '@msdyn365-commerce/core';


/**
 * Utility function to extract the active productId in the following priority:
 * First query param (productId), then UrlToken (itemId), then module config.
 * @param inputData - The Action Input data.
 * @returns Productid or undefined.
 */
export const getSelectedProductIdFromActionInput = (inputData: ICreateActionContext<IGeneric<IAny>>): string | undefined => {
    const context = inputData.requestContext;
    if (context.query?.productId) {
        return context.query.productId;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Exsiting code.
    } else if (
        context.urlTokens &&
        inputData.requestContext.urlTokens.itemId &&
        inputData.requestContext.urlTokens.pageType === 'Product'
    ) {
        return context.urlTokens.itemId;
    } else if (inputData && inputData.config && inputData.config.productId) {
        return inputData.config.productId;
    }
    return undefined;
};