import { ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
var axios = require('axios');

export const getRestrictedProducts = async (productsData: ProductSearchResult[],  attributesBaseUrl?: string): Promise<number[]> => {
    const restrictedProducts: number[] = [];
    for (const product of productsData) {
        const attributes = await _getRestrictedPostCodes(product.RecordId, attributesBaseUrl);
        attributes &&
            attributes.map((attr: any) => {
                if (attr?.Name && attr.Name === 'RestrictedPostCode' && attr.TextValue) {
                    // @ts-ignore
                    const postcodesTemp = JSON.parse(attr?.TextValue).RestrictedPostCode;
                    if (postcodesTemp.length > 0) {
                        restrictedProducts.push(product.RecordId);
                    }
                }
            });
    }

    return restrictedProducts;
};

export const _getRestrictedPostCodes = async (productId: number, attributesBaseUrl?: string): Promise<any> => {
    let url = attributesBaseUrl ? `${attributesBaseUrl}Commerce/Products(${productId})/GetAttributeValues(channelId=5637147577,catalogId=0)?$top=1000&api-version=7.3`: `https://scu40oznal298244666-rs.su.retail.dynamics.com/Commerce/Products(${productId})/GetAttributeValues(channelId=5637147577,catalogId=0)?$top=1000&api-version=7.3`;
    var config = {
        method: 'get',
        url: url,
        headers: {
            authority: attributesBaseUrl ? attributesBaseUrl.split('://')[1] : 'scu40oznal298244666-rs.su.retail.dynamics.com',
            accept: 'application/json',
            'accept-language': 'en-GB',
            appsessionid: 'OtVVxv4TZOd4WbCSDTkvYH',
            'cache-control': 'no-cache',
            'content-type': 'application/json',
            contenttype: 'application/json',
            'from-keystone': 'true',
            'odata-maxversion': '4.0',
            'odata-version': '4.0',
            oun: 'Y100',
            pragma: 'no-cache',
            prefer: 'return=representation',
            requestid: 'e42018722d257bb82e53ad56d5d963fc/2',
            usersessionid: 'ADCehL+M8YWP9iwro4TOSA'
        }
    };

    try {
        const response = await axios(config);
        return response.data.value;
    } catch (error) {
        console.log('err', error);
        throw error; // rethrow the error if needed
    }
};
