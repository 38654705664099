import { getSelectedProductIdFromActionInput } from '@msdyn365-commerce-modules/retail-actions';
import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';

import {
    getByIdAsync,
    getDefaultComponentsAsync,
    getVariantsByComponentsInSlotsAsync,
    getVariantsByDimensionValuesAsync
} from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { ProductDimension, SimpleProduct } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { generateProductImageUrl } from './utilities/get-image-url';
/**
 * Get selected variant action input class
 */
export class SelectedVariantInput implements IActionInput {
    public productId: number;
    public channelId: number;
    public matchingDimensionValues: ProductDimension[];

    constructor(productId: number, channelId: number, matchingDimensionValues?: ProductDimension[]) {
        this.productId = productId;
        this.channelId = channelId;
        this.matchingDimensionValues = matchingDimensionValues || [];
    }

    public getCacheKey = () => `SelectedVariant`;
    public getCacheObjectType = () => 'SimpleProduct';
    public dataCacheType = (): CacheType => 'application';
}

/**
 * CreateInput method for the getSelectedVariant data action
 * @param inputData The input data passed to the createInput method
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): SelectedVariantInput => {
    const productId = getSelectedProductIdFromActionInput(inputData);

    if (productId) {
        return new SelectedVariantInput(+productId, +inputData.requestContext.apiSettings.channelId, []);
    } else {
        throw new Error('Unable to create SelectedVariantInput, no productId found on module config or query');
    }
};

/**
 * Action method for the getSelectedVariant data aciton
 * @param input The action input class
 * @param ctx The action context
 */
async function getSelectedVariantAction(input: SelectedVariantInput, ctx: IActionContext): Promise<SimpleProduct | null> {
    let product: SimpleProduct | null = null;

    const response = await getByIdAsync({ callerContext: ctx }, input.productId, input.channelId);
    const baseProduct: SimpleProduct = Array.isArray(response) ? response[0] : response;

    // Need to dereference this before editing it. Otherwise we might not
    // properly get the mobx events because if things aren't properly observable
    // they won't fire when you set them, and then if you don't deref the value in
    // the cache will match the value when you try to save it, so it won't detect any
    // changes there either
    product = baseProduct && { ...baseProduct };

    if (product) {
        let baseProductHadUnmatchedDimension: boolean = false;
        if (product.Dimensions) {
            product.Dimensions.map(dimension => {
                const matchedTargetDimension = input.matchingDimensionValues?.find(
                    targetDimension => targetDimension.DimensionTypeValue === dimension.DimensionTypeValue
                );

                if (matchedTargetDimension) {
                    dimension.DimensionValue = matchedTargetDimension.DimensionValue;
                } else {
                    baseProductHadUnmatchedDimension = true;
                }
            });
        }

        if (!baseProductHadUnmatchedDimension && input.matchingDimensionValues?.length > 0) {
            const variants = await getVariantsByDimensionValuesAsync(
                { callerContext: ctx, queryResultSettings: {} },
                baseProduct?.RecordId,
                input.channelId,
                input.matchingDimensionValues || []
            );

            if (variants && variants.length > 0) {
                product = variants[0];
            }
        }

        /**
         *  VSI Customization -- STARTS
         *  Check if the product is Kit through getDefaultComponentsAsync
         *  If it is kit product then fetch and load it's variant instead of master product.
         *  Assuming there will be only one vaiant of kit product.
         */

        const defaultComponent = await getDefaultComponentsAsync(
            { callerContext: ctx, queryResultSettings: {} },
            baseProduct?.RecordId,
            input.channelId
        );

        if (defaultComponent && defaultComponent.length > 0) {
            const variantsByComponent = await getVariantsByComponentsInSlotsAsync(
                { callerContext: ctx, queryResultSettings: {} },
                baseProduct?.RecordId,
                input.channelId,
                []
            );

            if (variantsByComponent.length && variantsByComponent.length > 0) {
                const variantId = variantsByComponent[0]?.RecordId;
                const simpleProduct = await getByIdAsync({ callerContext: ctx }, variantId, input.channelId);
                const kitProduct: SimpleProduct = Array.isArray(simpleProduct) ? simpleProduct[0] : simpleProduct;
                // Will use master product's details as that would be up-to-date
                /* As description & title doesn't get updated for variants but for master products so using master product's details */
                const updatedKitProduct = kitProduct;
                updatedKitProduct.Name = baseProduct.Name;
                updatedKitProduct.Description = baseProduct.Description;

                product = { ...updatedKitProduct };
            }
        }

        /**
         *  VSI Customization -- ENDS
         */

        const newImageUrl = generateProductImageUrl(product, ctx.requestContext.apiSettings);

        if (newImageUrl) {
            product.PrimaryImageUrl = newImageUrl;
        }
    }

    return product || null;
}

/**
 * The complete getSelectedVariant data action
 */
export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-selected-variant',
    action: <IAction<SimpleProduct | null>>getSelectedVariantAction,
    input: createInput
});
