import { ICommerceApiSettings } from '@msdyn365-commerce/core';
import { ProductSearchResult, SimpleProduct } from '@msdyn365-commerce/retail-proxy';

/**
 * Generates a Image URL based on data return from retail server
 * @param imageUrl The image url returned by Retail Server
 * @param ctx The request context
 */
export const generateImageUrl = (imageUrl: string | undefined, apiSettings: ICommerceApiSettings): string | undefined => {
    if (imageUrl) {
        // Images hosted in CMS include schema
        if (imageUrl.startsWith('http')) {
            return imageUrl;
        }

        // Images hosted in Retail Server must be encoded and joined with the base image url
        return apiSettings.baseImageUrl + encodeURIComponent(imageUrl);
    } else {
        // d365Commerce.telemetry.warning(`Unable to generate a proper Image URL for Product: ${product.RecordId}`);
        return undefined;
    }
};

/**
 * Generates a Image URL for a product based on data return from retail server
 * @param product The Product returned by Retail Server
 * @param ctx The request context
 */
export const generateProductImageUrl = (
    product: SimpleProduct | ProductSearchResult,
    apiSettings: ICommerceApiSettings
): string | undefined => {
    return generateImageUrl(product.PrimaryImageUrl, apiSettings);
};
